import { AuthClient } from '@ingka-group-digital/people-auth';
import { KapplaRoute, ROUTE_UNAUTHORIZED } from './routes';

export enum UserPermission {
    /**
     * Access to the modulation feature
     */
    ENABLE_MODULATION = 'ENABLE_MODULATION',

    /**
     * Access to view OM filter on departments level
     */
    DEPARTMENTS_VIEW = 'DEPARTMENTS_VIEW',

    /**
     * Ability to create a master scenario (note: not main, but master as per discussions around France)
     */
    CREATE_MASTER_SCENARIO = 'kappla-master-scenario.create',

    // API GET related permissions

    /**
     * Access to get PA data
     */
    READ_PA_DATA = 'kappla-pa-data.read',

    /**
     * Access to get OM data
     */
    READ_OM_DATA = 'kappla-om-data.read',

    /**
     * *CLARIFY* - Access to config api?
     */
    READ_CONFIG = 'kappla-config.read',

    /**
     * Access to get data for each scenario
     */
    READ_SCENARIOS = 'kappla-scenarios.read',

    /**
     * Access to fetch and view list of scenarios
     */
    READ_SCENARIOS_LIST = 'kappla-scenarios-list.read',

    /**
     * Access to get input file data & download input files
     */
    READ_INPUTS = 'kappla-inputs.read',

    // App-wide permissions

    /**
     * *CLARIFY* - Access to upload all input files. Difference between these two? Remove 1?
     */
    EDIT_INPUTS = 'kappla-inputs.edit',

    /**
     * *CLARIFY* - Access to upload all input files. Difference between these two? Remove 1?
     */
    UPLOAD_INPUTS = 'kappla-inputs.upload',

    /**
     * Access to the upload/download modal (hide/show button) (currently feature flag)
     */
    CAPACITY_FACTOR_VIEW = 'kappla-capacity-factor.view',

    /**
     * Access to copy a scenario.
     */
    COPY_SCENARIO = 'kappla-scenario.copy',

    /**
     * Access to create a scenario.
     */
    CREATE_SCENARIO = 'kappla-scenario.create',

    /**
     * Allows user to delete a scenario.
     */
    DELETE_SCENARIO = 'kappla-scenario.delete',

    /**
     * Allows user to edit scenario data - renaming the scenario, switching which scenario is main,
     * make weekly changes, contract changes, add dummy CW.
     */
    EDIT_SCENARIO = 'kappla-scenario.edit',

    // Route/page permissions

    /**
     * Access to the capacity overview page
     */
    VIEW_CAPACITY_OVERVIEW = 'kappla-capacity-overview.view',

    /**
     * Access to the manage capacity page
     */
    VIEW_MANAGE_CAPACITY = 'kappla-manage-capacity.view',

    /**
     * Access to the action plan page (currently feature flag)
     */
    VIEW_ACTION_PLAN = 'kappla-action-plan.view',

    /**
     * Access to the recruitment needs page (currently feature flag)
     */
    VIEW_RECRUITMENT_NEEDS = 'kappla-recruitment-needs.view',

    /**
     * Access to the configuration page (currently feature flag)
     */
    VIEW_CONFIGURATION = 'kappla-configuration.view',

    // Page permissions - Capacity Overview
    /**
     * Used to show/hide the contract mix cards and graph (currently also feature flag)
     */
    READ_CONTRACT_MIX = 'kappla-contract-mix.read',

    // Page permissions - Manage Capacity

    // Page permissions - Action Plan

    /**
     * Access to fetch and view action plan data
     */
    READ_ACTION_PLAN = 'kappla-action-plan.read',

    /**
     * Access to edit action plan notes.
     */
    EDIT_ACTION_PLAN = 'kappla-action-plan.edit',

    // Page permissions - Configuration

    /**
     * Access to the onboarding new units in the config page
     */
    ONBOARD_NEW_UNITS = 'kappla-new-units.onboard',

    /**
     * Access to enable/disable units in the config page
     */
    ENABLE_DISABLE_UNITS = 'kappla-units.enable-disable',

    /**
     * Access to the config setting to onboard a new country
     */
    ONBOARD_COUNTRY = 'kappla-country.onboard',

    // Page permissions - Recruitment Needs
    /**
     * Access to read recruitment needs data
     */
    READ_RECRUITMENT_DASHBOARD = 'kappla-recruitment-dashboard.read',
    /**
     * Access to upload / download coworker turnover rates for excel file
     */
    UPLOAD_RECRUITMENT_NEEDS = 'kappla-recruitment-needs.upload',
}

export type Country = {
    code: string;
    name: string;
    isDefault: boolean;
};

export type Group = {
    id: string;
    displayName: string;
};

export type UserProfile = {
    businessUnit: string;
    country: string;
    email: string;
    name: string;
    officeLocation: string;
    role: string;
    image: string | null;
    rolePermissions: UserPermission[];
};

export type AuthAPI = {
    countries: Country[];
    userProfile: UserProfile;
};

/**
 * Map over all routes in Kappla, omitting unauthorized route
 */
export type UserAccessRoutes = {
    [TKey in KapplaRoute as TKey extends typeof ROUTE_UNAUTHORIZED ? never : TKey]: boolean;
};

/**
 * All features that can be conditionally enabled/disabled in the app.
 * Feature accesses based on user alone have boolean values.
 * Feature accesses which depend on user as well as some state of the app have values which are functions that return a boolean.
 */
export type UserAccessFeatures = {
    capacityFactorView: boolean;
    contractMixView: boolean;
    /**
     * Department view can be country specific, so it requires the current country to be passed in.
     */
    departmentsView: (currentCountry: string | undefined) => boolean;
    enableDisableUnits: boolean;
    onboardingNewUnits: boolean;
    modulation: boolean;
    recruitmentNeedsView: boolean;
};

export type AuthAccess = {
    api: UserAPIPermissions;
    features: UserAccessFeatures | undefined;
    routes: UserAccessRoutes | undefined;
};

export type AuthContextValues = {
    authClient: ReturnType<typeof AuthClient>;
    user: UserProfile | null;
    access: AuthAccess | undefined;
    isLoading: boolean;
    error: any;
    countriesList: Country[] | undefined;
};

export type UserAPIPermissions = {
    editScenario: boolean;
    deleteScenario: boolean;
    readInputs: boolean;
    uploadInputs: boolean;
    readPAData: boolean;
    readOMData: boolean;
    createMasterScenario: boolean;
    readContractMix: boolean;
    createScenario: boolean;
    readActionPlan: boolean;
    editActionPlan: boolean;
    readScenariosList: boolean;
    readScenarios: boolean;
    readConfig: boolean;
    copyScenario: boolean;
    onBoardNewUnits: boolean;
    enableDisableUnits: boolean;
    onBoardCountry: boolean;
    readRecruitmentNeeds: boolean;
    uploadRecruitmentNeeds: boolean;
};
