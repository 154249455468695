import React from 'react';
import { UserProfile } from 'types/authContext';
import { useTranslation } from 'react-i18next';
import Text from '@ingka/text';
import Hyperlink from '@ingka/hyperlink';
import { translateWithFallback } from 'utils/translations';
import Avatar from '../Avatar/Avatar';
import classes from './UserInfoPanel.module.scss';
import UserName from './UserName';

const UserInfoPanel = ({ user }: { user: UserProfile | null }) => {
    const { t, i18n } = useTranslation();

    return (
        <div data-testid="user-info-panel">
            <Avatar imageSrc={user?.image ?? null} size="large" />
            <UserName userName={user?.name} />
            {user
                && (
                    <>
                        <Text tagName="h6" bodySize="l" headingSize="s" className={classes['user-info']}>
                            {t('ROLE')}
                        </Text>
                        <Text className={classes['user-info']}>
                            {user.role}
                        </Text>

                        <Text tagName="h6" bodySize="l" headingSize="s" className={classes['user-info']}>
                            {t('COUNTRY')}
                        </Text>
                        <Text className={classes['user-info']}>
                            {translateWithFallback(`countryCodes.${user.country}`, user.country, t, i18n)}
                        </Text>

                        <Text tagName="h6" bodySize="l" headingSize="s" className={classes['user-info']}>
                            {t('UNIT')}
                        </Text>
                        <Text className={classes['user-info']}>
                            {user.businessUnit}
                        </Text>

                        <Text tagName="h6" bodySize="l" headingSize="s" className={classes['user-info']}>
                            {t('OFFICE_LOCATION')}
                        </Text>
                        <Text className={classes['user-info']}>
                            {user.officeLocation}
                        </Text>
                        <Hyperlink
                            text={t('PRIVACY_POLICY')}
                            className={classes['user-info']}
                            key="privacy-policy"
                            rel="noreferrer"
                            url={process.env.REACT_APP_URLS_user_privacy_policy_url}
                            newWindow
                            target="_blank"
                        />
                    </>
                )}

        </div>
    );
};

export default UserInfoPanel;
